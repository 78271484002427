export const Sun = () => (
  <svg
    role="img"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    height="4.5em"
    width="4.5em"
  >
    <path
      fill="#FF8C00"
      d="M494.103 221.914l-59.788-40.498 13.697-70.996c2.6-13.2-1.6-26.8-11.097-36.399-9.598-9.5-23.196-13.699-36.193-11.1l-70.886 13.7-40.393-59.897c-15.097-22.299-51.89-22.299-66.986 0l-40.393 59.897-70.786-13.7c-13.297-2.499-26.795 1.6-36.293 11.2-9.498 9.6-13.697 23.1-11.097 36.299l13.697 70.996-59.788 40.498C6.599 229.514 0 242.014 0 255.513c0 13.499 6.699 25.998 17.797 33.498l59.788 40.498-13.697 70.996c-2.6 13.2 1.6 26.8 11.097 36.299 9.498 9.5 22.896 13.699 36.293 11.1l70.786-13.7 40.393 59.897C229.955 505.3 242.553 512 255.95 512c13.397 0 25.995-6.7 33.493-17.8l40.393-59.896 70.886 13.7c13.397 2.699 26.794-1.6 36.293-11.1 9.498-9.5 13.597-23.1 11.097-36.299l-13.697-70.996 59.788-40.498c11.098-7.5 17.797-20.1 17.797-33.498-.1-13.6-6.699-26.1-17.897-33.699zM381.226 307.51l17.596 91.196-90.982-17.6-51.89 76.897-51.89-76.997-90.882 17.6 17.596-91.196-76.785-51.997 76.785-51.998-17.596-91.195 90.982 17.599 51.79-76.797 51.89 76.897 90.982-17.6-17.596 91.096 76.785 51.998zM255.95 152.018c-57.289 0-103.98 46.697-103.98 103.995 0 57.297 46.691 103.994 103.98 103.994s103.98-46.697 103.98-103.995c0-57.297-46.691-103.994-103.98-103.994zm0 159.992c-30.894 0-55.989-25.099-55.989-55.997 0-30.899 25.095-55.998 55.989-55.998s55.99 25.099 55.99 55.998c0 30.898-25.096 55.997-55.99 55.997z"
    />
  </svg>
);
